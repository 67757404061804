import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Slide from '../components/slide'
import InterviewTag from "../components/interview-tag"
import { formatDate } from "../components/data-control"

export default function Home({ data }) {
  const { categorys, categoryHeadings, interviwCategorys } = useSiteMetadata()
  const slides = [] //Topのカルーセルはインタビューの各カテゴリの最新記事
  for (const category of interviwCategorys) {
      if (data[category].nodes.length > 0) {
        const post = data[category].nodes[0]
        slides.push({
          src: `/img/${category}/${post.fields.basename}/top.jpg`,
          category,
          categoryName: categorys[category],
          update: formatDate(post.frontmatter.date),
          title: post.frontmatter.title,
          heading: post.frontmatter.heading,
          slug: post.fields.slug,
        })
      }
  }

  return (
    <Layout slug="/" showSchoolArea={true} {...data.meta.nodes[0]}>
      <div className="l-home-kv">
        <div className="p-home-kv-items" id="js-top-slide">
          <Slide data={slides} />
        </div>

        <div className="p-home-list-pickup">
          <div className="c-home-site-name">
            <span>めざせ!<br />歯科衛生士</span>
            <span>歯科衛生士になるための情報マガジン</span>
          </div>
          <ul>
            <li>
              <Link to="/naruniha">歯科衛生士に<span>なるには</span></Link>
            </li>
            <li>
              <Link to="/shigoto">歯科衛生士の<span>仕事内容</span></Link>
            </li>
            <li>
              <Link to="/school">歯科衛生士の<span>学校情報</span></Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-static-list p-static-list-charm">
        <h2>歯科衛生士の魅力</h2>

        <div className="c-static-charm-nav">
          <ul>
            <li>
              <Link to="/salary">大卒より<em className="u-bgc-pink">高い初任給</em></Link>
            </li>
            <li>
              <Link to="/shikaku">一生使える<em className="u-bgc-pink">国家資格</em></Link>
            </li>
            <li>
              <Link to="/employment">就職率はなんと<em className="u-bgc-pink">9割以上</em></Link>
            </li>
            <li>
              <Link to="/hobby">趣味や遊びも<em className="u-bgc-pink">両立</em></Link>
            </li>
            <li>
              <Link to="/medical">患者さんを<em className="u-bgc-pink">笑顔にする医療職</em></Link>
            </li>
          </ul>
        </div>
      </div>

      {interviwCategorys.map((category) => (
        <div className={`p-interview-list p-interview-list-${category}`}>
          <span>{categoryHeadings[category]}</span>
          <h2>{categorys[category]}</h2>

          <div className="p-interview-list-items cols3">
            {data[category].nodes.map((data) => (
              <InterviewTag.Card data={{...data.fields, ...data.frontmatter}} />
            ))}
          </div>

          <Link to={`/${category}`} className={`c-button c-button-${category}`}>{categorys[category]} 一覧</Link>
        </div>

      ))}

      <QaBlock />
    </Layout>
  )
}

export const query = graphql`
  query {
    meta: allMeta(filter: {slug: {eq: "/"}}) {
      nodes {
        title
        description
        keywords
      }
    }
    voice: allMdx(filter: {fields: {category: {eq: "voice"}}}, limit: 3, sort: {fields: [frontmatter___date, fields___basename], order: [DESC, DESC]}) {
      nodes {
        fields {
          slug
          category
          basename
        }
        frontmatter {
          date
          title
          heading
          organization
          position
          interviewee
        }
      }
    }
    active: allMdx(filter: {fields: {category: {eq: "active"}}}, limit: 3, sort: {fields: [frontmatter___date, fields___basename], order: [DESC, DESC]}) {
      nodes {
        fields {
          slug
          category
          basename
        }
        frontmatter {
          date
          title
          heading
          organization
          position
          interviewee
        }
      }
    }
    future: allMdx(filter: {fields: {category: {eq: "future"}}}, limit: 3, sort: {fields: [frontmatter___date, fields___basename], order: [DESC, DESC]}) {
      nodes {
        fields {
          slug
          category
          basename
        }
        frontmatter {
          date
          title
          heading
          organization
          position
          interviewee
        }
      }
    }
    qa: allMdx(filter: {fields: {category: {eq: "qa"}}}, limit: 3, sort: {fields: [frontmatter___date, fields___slug], order: [DESC, ASC]}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date
          title
        }
      }
    }
  }
`

const QaBlock = () => {
  return (
      <div className="p-latest-list p-latest-list-qa">
          <span>基礎知識</span>
          <h2>歯科衛生士Q&amp;A</h2>
          <div className="c-qa-nav">
              <ul>
                  <li><Link to="/qa/001/">どうしたら歯科衛生士になれるの？</Link></li>
                  <li><Link to="/qa/002/">卒業後の就職先は？</Link></li>
                  <li><Link to="/qa/003/">歯科衛生士の給料は？</Link></li>
                  <li><Link to="/qa/004/">歯科衛生士ってどんな仕事？</Link></li>
                  <li><Link to="/qa/005/">歯科衛生士学校で学ぶことは？</Link></li>
                  <li><Link to="/qa/006/">中卒でも歯科衛生士になれる？</Link></li>
              </ul>
          </div>
          <Link to="/qa" className="c-button c-button-qa">歯科衛生士Q&amp;A 一覧</Link>
      </div>
  )
}