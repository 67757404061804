import React from 'react';
import { Link, withPrefix } from "gatsby"
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import PropTypes from 'prop-types'

const Slide = ({ data }) => {
  return (<Carousel
    infiniteLoop={true}
    showThumbs={false}
    showStatus={false}
    autoPlay={true}
    interval={5000}
  >
    {data.map((slide, i) => {
      return (<div key={`${i}-slide`} className={`p-home-kv-item ${slide.category}`}>
        <Link to={slide.slug}>
          <div className='c-home-kv-data'>
            <div className='category'>
              <mark>{slide.categoryName}</mark>
            </div>
            <div className='update'>
              <mark>{slide.update}</mark>
            </div>
            <h2>
              <mark dangerouslySetInnerHTML={{ __html: slide.heading }} />
            </h2>
          </div>
          <img src={withPrefix(slide.src)} style={{width:"100%", height:"100%"}} alt={slide.title} />
        </Link>
      </div>);
    })}
  </Carousel>);
};

Slide.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.required,
    title: PropTypes.string.required,
    update: PropTypes.string.string,
    category: PropTypes.string.string
  })).isRequired
};


export default Slide;
