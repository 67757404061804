import React from "react"
import { Link, withPrefix } from "gatsby"

const InterviewTag = {
    Card: ({data}) => {
        const {slug, basename, category, title, organization, position, interviewee} = data
        return (
        <div className="c-interview-list-item col">
            <Link to={slug}>
                {CardImage({category, basename, title})}
                <div className="c-interview-list-item-data">
                    <span className="title">{title}</span>
                    {intervieweeName({category, organization, position, interviewee})}
                </div>
            </Link>
        </div>
        )
    },
    CardImage: ({category, basename, title}) => {
        return CardImage({category, basename, title})
    },
    Interviewee: ({category, organization, position, interviewee, interviewee_furigana=""}) => {
        return intervieweeName({category, organization, position, interviewee, interviewee_furigana})
    },
    Tag: ({tags}) => {
        if (!Array.isArray(tags) || tags.length === 0) return false
        return (
            <ul>
                {tags.map((tag) => (
                    <li>#{tag}</li>
                ))}
            </ul>
        )
    }
}

const intervieweeName = ({category="", organization, position, interviewee, interviewee_furigana=""}) => {
    const organizationClass = {
        voice: "school",
        active: "job",
        future: "job"
    }
    const className = organizationClass[category] ? organizationClass[category] : false
    const name = (() => {
        if (!interviewee) return ""
        const intervieweeAry = interviewee.replaceAll("、", ",").replaceAll("，", ",").split(",")
        const interviewee_furiganaAry = interviewee_furigana.replaceAll("、", ",").replaceAll("，", ",").split(",")
        const names = []
        for (let i=0; i<intervieweeAry.length; i++) {
            const interviewee = intervieweeAry[i]
            const furigana = interviewee_furiganaAry[i]
            names.push(`${interviewee}${furigana ? `（${furigana}）` : ""}さん`)
        }
        return names.join('・')
    })()
    return (
    <React.Fragment>
        <span className={className}>{organization}{position}</span>
        <span className="name">{name}</span>
    </React.Fragment>
    )
}

const CardImage = ({category, basename, title}) => {
    return (
    <figure>
        <img src={withPrefix(`/img/${category}/${basename}/card.jpg`)} style={{width:"100%", height:"100%"}} alt={title} />
    </figure>
    )
}

export default InterviewTag